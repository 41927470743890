<template>
    <div class="home-page">
        <div class="home-base">
            <zj-tianditu :zoom="4"></zj-tianditu>
            <div class="mask"></div>
        </div>
        
        <div class="home-minimize left" :class="isShowLeftArea ? '' : 'min'" :title="isShowLeftArea?'隐藏左侧区域面板':'显示左侧区域面板'" @click="onShowLeftArea">
            <img v-if="isShowLeftArea" src="@/static/icons/icon-double-left.svg" alt="">
            <img v-else src="@/static/icons/icon-double-right.svg" alt="">
        </div>
        <div class="home-minimize right" :class="isShowRightArea ? '' : 'min'" :title="isShowRightArea?'隐藏右侧区域面板':'显示右侧区域面板'"  @click="onShowRightArea">
            <img v-if="isShowRightArea" src="@/static/icons/icon-double-right.svg" alt="">
            <img v-else src="@/static/icons/icon-double-left.svg" alt="">
        </div>
        
        <zj-Layout class="home-layout" :class="[isShowLeftArea ? '' : 'minimize-left', isShowRightArea ? '' : 'minimize-right', !isShowLeftArea && !isShowRightArea ? 'minimize-both' : '']" :config="layoutConfig" >
            <template #header>
                <!-- <com-header /> -->
                <com-top-bar />
            </template>
            
            <template #left-area >
                <template v-for="it in moduleList">
                    <template v-if="it.show !== false && it.slot === 'left-area' && isShowLeftArea">
                        <zj-widget v-if="it.window" :title="it.title">
                            <component :is="it.component" />
                        </zj-widget>
                        <component v-else :is="it.component" />
                    </template>
                </template>
                
            </template>
            
            <template #right-area >
                <template v-for="it in moduleList">
                    <template v-if="it.show !== false && it.slot === 'right-area' && isShowRightArea">
                        <zj-widget v-if="it.window" :title="it.title">
                            <component :is="it.component" />
                        </zj-widget>
                        <component v-else :is="it.component" />
                    </template>
                </template>
            </template>
            
            <template #center-up-area>
                <template v-for="it in moduleList">
                    <template v-if="it.show !== false && it.slot === 'center-up-area'">
                        <component v-if="it.window" :title="it.title" :is="it.window ? it.window : 'zj-widget'">
                            <component :is="it.component" />
                        </component>
                        <component v-else :is="it.component" />
                    </template>
                </template>                
            </template>
            
            <template #center-down-area>
                <template v-for="it in moduleList">
                    <template v-if="it.show !== false && it.slot === 'center-down-area'">
                        <zj-widget v-if="it.window" :title="it.title">
                            <component :is="it.component" />
                        </zj-widget>
                        <component v-else :is="it.component" />
                    </template>
                </template>                
                <com-navigator />
            </template>
            
            <template #footer>
                
            </template>
        </zj-Layout>
    </div>
</template>
 
<script>
    import ajax from "@/ajax.js";
    
    import ZjLayout from 'zj-layout';
    import ZjTianditu from 'zj-tianditu';
    import ZjScrollList from 'zj-scroll-list';
    import ZjWidget from 'zj-widget';
    import {ZjDragWidget} from 'zj-widget';
    
    // simple usage
    const AsyncComponents = {}
    
    AsyncComponents['ComNavigator'] = () => import('./com-navigator.vue');
    AsyncComponents['ComTime'] = () => import('./com-time.vue');
    AsyncComponents['ComSoldiers'] = () => import('./com-soldiers.vue');
    AsyncComponents['ComEarlyWarnings'] = () => import('./com-early-warnings.vue');
    AsyncComponents['ComSentries'] = () => import('./com-sentries.vue');
    AsyncComponents['ComThreeModel'] = () => import('./com-three-model.vue');
    AsyncComponents['ComVrPanorama'] = () => import('./com-vr-panorama.vue');
    AsyncComponents['ComShowWeather'] = () => import('./com-show-weather.vue');
    AsyncComponents['ComDevices'] = () => import('./com-devices.vue');
    AsyncComponents['ComLines'] = () => import('./com-lines.vue');
    AsyncComponents['ComFlowLoop'] = () => import('./com-flow-loop.vue');
    AsyncComponents['ComLinesHistory'] = () => import('./com-lines-history.vue');
    
    export default {
        components: {
            ZjLayout,
            ZjTianditu,
            ZjScrollList,
            ZjWidget,
            ZjDragWidget,
            ComHeader: () => import('./com-header.vue'),
            ComTopBar: () => import('./com-top-bar.vue'),
            ...AsyncComponents,
        },
        computed: {
        },
        data: () => ({
            isHtmlFrame: false,
            isShowLeftArea: true,
            isShowRightArea: true,
            layoutConfig: {},
            moduleList: [],
            dataList: [
                {
                    key: "key1",
                    row: [
                        { text: "默认标题", },
                        { text: "状态", text_color: 'green' },
                        { list: [
                            { emit: "play" },
                            { emit: "stop" },
                        ] },
                    ],
                },
            ],
        }),
        mounted(){
            this.downloadConfig();
            console.log("ZjDragWidget：", ZjDragWidget)
        },
        methods: {
            downloadConfig(){
                ajax({url: "/api/getConfig"}).then(res => {
                    // console.log("downloadConfig:", res);
                    this.layoutConfig = res.layoutConfig;
                    // this.$set(this, "moduleList", res.moduleList);
                    this.moduleList = res.moduleList;
                    // console.log("shacq", this.moduleList)
                    this.$forceUpdate();
                })
            },
            onShowLeftArea(){
                this.isShowLeftArea = !this.isShowLeftArea;
                // if(this.isShowLeftArea){
                //     this.$store.dispatch('showWindow', 'comEarlyWarnings')
                //     this.$store.dispatch('showWindow', 'comSentries')
                // }
                // else{
                //     this.$store.dispatch('closeWindow', 'comEarlyWarnings')
                //     this.$store.dispatch('closeWindow', 'comSentries')
                // }
            },
            onShowRightArea(){
                this.isShowRightArea = !this.isShowRightArea
                // if(this.isShowRightArea){
                //     this.$store.dispatch('showWindow', 'comWeather')
                //     this.$store.dispatch('showWindow', 'comSoldiers')
                // }
                // else{
                //     this.$store.dispatch('closeWindow', 'comWeather')
                //     this.$store.dispatch('closeWindow', 'comSoldiers')
                // }
            },
        },
    }
</script>

<style>
@import "./home-page.css";

.home-page{}

/* 解决一些样式冲突的问题 */
.home-page .zj-drag-widget{width: 100%; }

</style>